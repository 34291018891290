import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

// local modules
import LanguageStore from "./modules/language";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    LanguageStore,
  },
  plugins: [vuexLocalStorage.plugin],
});
