<template>
  <div>
    <select
      v-model="$i18n.locale"
      class="border border-gray-300 rounded"
      @change="handleChange($event)"
    >
      <option value="en">English</option>
      <option value="am">አማርኛ</option>
    </select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "LocaleSwitcher",
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      doChangeLanguage: "LanguageStore/doChangeLanguage",
    }),
    handleChange(event) {
      this.doChangeLanguage(event.target.value);
      localStorage.setItem("lang", event.target.value);
    },
  },
};
</script>
