import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About";
import Vision from "../views/OurVision.vue";
import Contact from "../views/Contact";
import Definition from "../views/stroke/Definition.vue";
import Types from "../views/stroke/TypesOfStroke.vue";
import Symptoms from "../views/stroke/Symptoms.vue";
import Risks from "../views/stroke/RiskFactors.vue";
import Treatment from "../views/projects/Treatment.vue";
import Research from "../views/projects/Research.vue";
import Training from "../views/projects/Training.vue";
import Education from "../views/projects/Education.vue";
import Imprint from "../views/Imprint";
import PrivacyPolicy from "../views/PrivacyPolicy";
Vue.use(VueRouter);
// Vue.prototype.$scrollToTop = () =>{
//   window.scrollTo(0, 0)
// }
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "About",
    component: About,
  },
  {
    path: "/our-vision",
    name: "Vision",
    component: Vision,
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/definition",
    name: "Definition",
    component: Definition,
  },
  {
    path: "/types-of-stroke",
    name: "Types",
    component: Types,
  },
  {
    path: "/symptoms",
    name: "Symptoms",
    component: Symptoms,
  },
  {
    path: "/risk-factors",
    name: "Risks",
    component: Risks,
  },
  {
    path: "/treatment",
    name: "Treatment",
    component: Treatment,
  },
  {
    path: "/research",
    name: "Research",
    component: Research,
  },
  {
    path: "/training",
    name: "Training",
    component: Training,
  },
  {
    path: "/education",
    name: "Education",
    component: Education,
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
