<template>
  <section class="text-gray-700">
    <div class="relative h-64 pb-1/3">
      <img
        :src="getBaseUrl() + research.attributes.image.data.attributes.url"
        alt=""
        class="absolute object-cover w-full h-full"
      />
    </div>
    <div class="container max-w-4xl px-4 py-16 mx-auto">
      <h1 class="mb-3 text-4xl font-semibold text-gray-800">
        {{ research.attributes.title }}
      </h1>
      <p class="leading-relaxed">
        <VueShowdown :markdown="research.attributes.content" />
      </p>
      <div class="mt-4">
        <a
          v-if="research.attributes.readMore"
          target="_blank"
          :href="research.attributes.readMore"
          class="text-sm transition-colors duration-300 cursor-pointer text-esi-secondary hover:underline"
        >
          Read more
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline-block w-5 h-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";
import { mapGetters } from "vuex";

export default {
  name: "Research",
  data() {
    return {
      research: {},
      loadingImage: require("../../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiResearch",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.research = response.data.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
