import { baseUrl } from "@/Api";
export default {
  namespaced: true,
  state: {
    lang: localStorage.getItem("lang") || "en",
  },
  mutations: {
    SET_LANG(state, payload) {
      state.lang = payload;
    },
  },
  actions: {
    doChangeLanguage({ commit, state }, payload) {
      // state change + localstorage
      commit("SET_LANG", payload);
    },
  },
  getters: {
    lang(state) {
      return state.lang;
    },
    // ?locale=am&populate=*
    apiVisions(state) {
      return `${baseUrl}/api/visions?locale=${state.lang}&populate=*&sort[0]=id`;
      // vision
    },
    apiVisionIntro(state) {
      return `${baseUrl}/api/vision-intro?locale=${state.lang}&populate=*`;
      // visionInto
    },

    apiHero(state) {
      return `${baseUrl}/api/hero?locale=${state.lang}&populate=*`;
      // hero
    },
    apiDefinition(state) {
      return `${baseUrl}/api/stroke-definition?locale=${state.lang}&populate=*`;
      // strokeDefinition
    },
    apiTypes(state) {
      return `${baseUrl}/api/stroke-type?locale=${state.lang}&populate=*`;
      // strokeTypes
    },
    apiSymptoms(state) {
      return `${baseUrl}/api/stroke-symptom?locale=${state.lang}&populate=*`;
      // strokeSymptom
    },
    apiRisks(state) {
      return `${baseUrl}/api/stroke-risk?locale=${state.lang}&populate=*`;
      // storkeRisk
    },
    apiAbouts(state) {
      return `${baseUrl}/api/abouts?locale=${state.lang}&populate=*&sort[0]=id`;
      // about
    },
    apiSinitiative(state) {
      return `${baseUrl}/api/stroke-initiative?locale=${state.lang}&populate=*`;
      // strokeInitiative
    },
    apiIntro(state) {
      return `${baseUrl}/api/intros?locale=${state.lang}&populate=*&sort[0]=id`;
      // intro
    },
    apiEducation(state) {
      return `${baseUrl}/api/education?locale=${state.lang}&populate=*`;
      // education
    },
    apiTreatment(state) {
      return `${baseUrl}/api/treatment?locale=${state.lang}&populate=*`;
      // treatment
    },
    apiAboutIntro(state) {
      return `${baseUrl}/api/about-intro?locale=${state.lang}`;
      // aboutIntro
    },
    apiResearch(state) {
      return `${baseUrl}/api/sresearch?locale=${state.lang}&populate=*`;
      // sresearch
    },
    apiTraining(state) {
      return `${baseUrl}/api/training?locale=${state.lang}&populate=*`;
      // training
    },
    apiFooter(state) {
      return `${baseUrl}/api/footer?locale=${state.lang}`;
      // footer
    },
    // apiLatestNews(state) {
    //   return `${baseUrl}/latest-news?locale=${state.lang}`;
    // },
  },
};
