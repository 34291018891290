<template>
  <div>
    <Hero></Hero>
    <Initiative></Initiative>
    <Intro></Intro>
  </div>
</template>

<script>
import Hero from "../components/Hero.vue";
import Initiative from "../components/StrokeInitiative.vue";
import Intro from "../components/Intro.vue";

// @ is an alias to /src

export default {
  name: "Home",
  components: {
    Intro,
    Initiative,
    Hero,
  },
};
</script>
<style></style>
