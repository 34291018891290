var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{style:({
      backgroundImage:
        'url(' +
        _vm.getBaseUrl() +
        _vm.item.attributes.image.data.attributes.url +
        ')',
      height: 600 + 'px',
      'object fit': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center',
      'z-index': '2',
      'background-size': 'cover',
      position: 'relative',
    })},[_c('div',{staticClass:"left-0 flex flex-col items-start justify-center h-full text-gray-200 bg-black md:items-center bg-opacity-40"},[_c('div',{staticClass:"p-4 mx-8 text-center md:pb-20"},[_c('p',{staticClass:"max-w-6xl mb-10 text-2xl font-bold text-gray-200 md:text-4xl"},[_vm._v(" "+_vm._s(_vm.item.attributes.title)+" ")]),_c('div',[_c('a',{staticClass:"px-6 py-3 text-xl text-center text-white transition-colors duration-300 border rounded border-esi-btn bg-esi-btn hover:bg-opacity-80",attrs:{"href":"https://donorbox.org/strike-out-stroke","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("donate-now"))+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }