<template>
  <div class="text-gray-100 bg-esi-primary">
    <footer class="max-w-6xl py-8 mx-auto sm:py-12">
      <div class="px-5">
        <div class="flex flex-wrap sm:flex sm:flex-wrap sm:-mx-4 md:py-4">
          <div class="w-1/4 px-4 md:w-1/4 xl:w-1/6">
            <h5 class="mb-6 text-xl font-bold">Sitemap</h5>
            <ul class="list-none footer-links">
              <li v-for="sitemap in sitemaps" :key="sitemap.name" class="mb-2">
                <router-link
                  @click.native="scrollToTop"
                  :to="sitemap.route"
                  class="transition-colors duration-300 hover:underline"
                >
                  {{ $t(`footer.${sitemap.name}`) }}</router-link
                >
              </li>
            </ul>
          </div>
          <div class="w-3/4 px-4 sm:w-2/3 xl:w-2/6 xl:mt-0 xl:ml-auto">
            <h5 class="mb-6 text-xl font-bold">{{ $t("contact-us") }}</h5>
            <div>
              <div>
                <h2 class="mb-2 text-sm font-bold tracking-widest title-font">
                  {{ $t("physical-address") }}
                </h2>
                <p class="mt-1 text-sm leading-relaxed">
                  <VueShowdown
                    :markdown="`${footer.attributes.physicalAddress}`"
                  />
                </p>
              </div>
              <div>
                <h2 class="mt-4 text-sm font-bold tracking-widest title-font">
                  {{ $t("email") }}
                </h2>
                <a
                  href="mailto:info@ethiopiastroke.com"
                  class="text-sm leading-relaxed bg-esi-secondary"
                >
                  <VueShowdown :markdown="`${footer.attributes.email}`" />
                </a>
                <h2 class="mt-4 text-sm font-bold tracking-widest">
                  {{ $t("phone") }}
                </h2>
                <p class="text-sm leading-relaxed tracking-wider">
                  <VueShowdown :markdown="`${footer.attributes.phone}`" />
                </p>
              </div>
            </div>
          </div>
          <div class="w-2/4 px-4 mt-8 sm:w-1/3 xl:w-1/6 xl:mt-0 xl:ml-auto">
            <h5 class="mb-6 text-xl font-bold">Legals</h5>
            <ul class="list-none footer-links">
              <li class="mb-2">
                <router-link
                  @click.native="scrollToTop"
                  to="/imprint"
                  class="transition-colors duration-300 hover:underline"
                >
                  Imprint
                </router-link>
              </li>
              <li class="mb-2">
                <router-link
                  @click.native="scrollToTop"
                  to="/privacy-policy"
                  class="transition-colors duration-300 hover:underline"
                >
                  Privacy policy
                </router-link>
              </li>
            </ul>
          </div>
          <div class="w-2/4 px-4 mt-8 sm:w-1/3 xl:w-1/6 xl:mt-0 xl:ml-auto">
            <h5 class="mb-6 text-xl font-bold sm:text-center xl:text-left">
              Stay connected
            </h5>
            <div
              class="flex flex-wrap justify-between space-x-2 sm:justify-center xl:justify-start"
            >
              <a
                v-for="item in social"
                :key="item.id"
                :href="item.attributes.url"
                class="px-1 py-1 rounded-full outline-none hover:bg-gray-200 focus:outline-none"
              >
                <img
                  :src="
                    getBaseUrl() + item.attributes.image.data.attributes.url
                  "
                  alt="socials"
                  class="w-6 h-6"
                />
              </a>
            </div>
          </div>
        </div>
        <div
          class="pt-6 mt-6 border-t border-gray-400 sm:flex sm:flex-wrap sm:-mx-4 sm:mt-12 sm:pt-12"
        >
          <div class="w-full px-4 text-sm text-center">
            <p>
              &copy;{{ new Date().getFullYear() }} Ethiopia Stroke Initiative
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  data: () => ({
    footer: {},
    social: {},
    imprint: {},
    // privacy: {},
    sitemaps: [
      {
        name: "home",
        route: "/",
      },
      {
        name: "about-us",
        route: "/about-us",
      },
      {
        name: "our-vision",
        route: "/our-vision",
      },
      {
        name: "contact-us",
        route: "/contact-us",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      apiFooter: "LanguageStore/apiFooter",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const social = await axios.get(`${baseUrl}/api/socials?&populate=*`);
        this.social = social.data.data;

        // const imprint = await axios.get(`${baseUrl}/Imprints`);
        // this.imprint = imprint.data;
        // `https://cms.ethiopiastroke.com/initiative-footer`
        const footer = await axios.get(this.apiFooter);

        this.footer = footer.data.data;

        // const privacy = await axios.get(`${baseUrl}/privacy-policy`);
        // this.privacy = privacy.data;

        // this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
