<template>
  <section class="overflow-hidden text-gray-600 body-font">
    <div class="container w-full px-5 py-20 mx-auto md:w-3/5 md:px-0">
      <div class="-my-8 divide-y-2 divide-gray-100">
        <div class="flex flex-wrap py-8 md:flex-nowrap">
          <div class="md:flex-grow">
            <p class="leading-relaxed">
              <VueShowdown :markdown="`${privacy.attributes.content}`" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "PrivacyPolicy",
  data: () => ({
    privacy: {},
  }),
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/api/privacy-policy`);
      this.privacy = response.data.data;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
