<template>
  <section class="text-gray-700">
    <div class="relative h-64 pb-1/3">
      <img
        :src="getBaseUrl() + education.attributes.image.data.attributes.url"
        :alt="education.attributes.title"
        class="absolute object-cover w-full h-full"
      />
    </div>

    <div class="container max-w-4xl px-4 py-16 mx-auto">
      <h1 class="mb-3 text-4xl font-semibold">
        {{ education.attributes.title }}
      </h1>
      <p class="leading-relaxed">
        <VueShowdown :markdown="`${education.attributes.content}`" />
      </p>
      <div class="mt-4">
        <router-link
          v-if="education.attributes.readMore"
          target="_blank"
          :href="education.attributes.readMore"
          class="px-3 py-2 text-center text-white transition-colors duration-300 border rounded border-axion-secondary hover:bg-yellow-700 bg-axion-secondary"
        >
          Read More
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";
import { mapGetters } from "vuex";

export default {
  name: "Education",
  data() {
    return {
      education: {},
      loadingImage: require("../../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiEducation",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.education = response.data.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
