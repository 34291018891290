<template>
  <section>
    <div
      :style="{
        backgroundImage:
          'url(' +
          getBaseUrl() +
          item.attributes.image.data.attributes.url +
          ')',
        height: 600 + 'px',
        'object fit': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
        'z-index': '2',
        'background-size': 'cover',
        position: 'relative',
      }"
    >
      <div
        class="left-0 flex flex-col items-start justify-center h-full text-gray-200 bg-black md:items-center bg-opacity-40"
      >
        <div class="p-4 mx-8 text-center md:pb-20">
          <p
            class="max-w-6xl mb-10 text-2xl font-bold text-gray-200 md:text-4xl"
          >
            {{ item.attributes.title }}
          </p>
          <div>
            <a
              href="https://donorbox.org/strike-out-stroke"
              target="_blank"
              class="px-6 py-3 text-xl text-center text-white transition-colors duration-300 border rounded border-esi-btn bg-esi-btn hover:bg-opacity-80"
            >
              {{ $t("donate-now") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Hero",
  data() {
    return {
      item: [],
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiHero",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.item = response.data.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
