<template>
  <div>
    <AboutIntro></AboutIntro>
    <section class="max-w-6xl mx-auto text-gray-600">
      <div v-if="!loading">
        <div class="px-5 py-20">
          <div
            class="grid items-center grid-cols-1 mb-24 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10"
            :class="index % 2 === 0 ? 'bg-blue-50' : 'bg-gray-100'"
            v-for="(about, index) in items"
            :key="about.id"
          >
            <div
              class="order-1"
              :class="index % 2 === 0 ? 'md:order-1 p-8' : 'md:order-2 p-8'"
            >
              <div class="mb-4">
                <h1
                  class="text-3xl font-semibold tracking-tighter text-center text-primary-green md:leading-tight sm:text-left md:text-4xl"
                >
                  {{ about.attributes.name }}
                </h1>
                <p class="font-medium">
                  {{ about.attributes.specialization }}
                </p>
              </div>
              <p
                class="mb-5 leading-relaxed text-gray-600 sm:text-left sm:rtl:text-right md:text-md"
              >
                <VueShowdown :markdown="about.attributes.profile" />
              </p>
            </div>
            <div
              class="flex flex-col w-full h-full"
              :class="index % 2 === 0 ? 'order-2' : 'order-1'"
            >
              <img
                :alt="about.attributes.name"
                :src="getBaseUrl() + about.attributes.image.data.attributes.url"
                class="mb-6 rounded"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <img :src="loadingImage" class="w-64 m-auto" />
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { baseUrl } from "../Api";
import AboutIntro from "../components/AboutIntro.vue";
import { mapGetters } from "vuex";

export default {
  name: "About",
  components: {
    AboutIntro,
  },
  data() {
    return {
      Wondwossen: require("../assets/images/hero/Wondwossen.jpeg"),
      items: [],
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiAbouts",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.items = response.data.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>
