<template>
  <div>
    <nav class="px-5 py-3 mx-auto bg-white text-esi-primary">
      <!--  container md:px-0 -->
      <div class="lg:flex lg:flex-wrap lg:items-center">
        <div class="flex items-center justify-between">
          <img src="@/assets/inititive-logo.svg" alt="Axon logo" class="h-24" />
          <button
            @click="toggle"
            class="px-3 py-1 border border-gray-400 border-solid rounded opacity-70 hover:opacity-75 lg:hidden"
            id="navbar-toggle"
          >
            <svg
              fill="none"
              stroke="currentColor"
              class="w-6 h-6"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        <div
          :class="open ? 'flex' : 'hidden'"
          class="flex-col mt-3 lg:flex lg:flex-row lg:ml-auto lg:mt-0"
          id="navbar-collapse"
        >
          <router-link
            v-for="nav in navs1"
            :key="nav.name"
            @click.native="scrollToTop"
            :to="nav.route"
            class="py-2 font-bold transition-colors duration-300 border-b-2 border-transparent hover:border-esi-btn lg:mx-4 md:mx-3"
          >
            {{ $t(`nav1.${nav.name}`) }}
          </router-link>
          <div class="relative inline-block dropdown">
            <button
              class="inline-flex items-center px-4 py-2 transition-colors duration-300 rounded lg:px-3 md:mx-2"
            >
              <span class="mr-1 font-bold">{{ $t("nav3.stroke") }}</span>
              <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </button>
            <ul
              class="absolute z-50 hidden w-48 bg-gray-100 border border-gray-200 rounded shadow-2xl dropdown-menu"
            >
              <router-link
                v-for="dropdown in dropdown"
                :key="dropdown.name"
                @click.native="scrollToTop"
                :to="dropdown.route"
                class="z-50 block px-4 py-2 whitespace-no-wrap border-l hover:bg-gray-200"
              >
                {{ $t(`stroke.${dropdown.name}`) }}
              </router-link>
            </ul>
          </div>
          <div class="relative inline-block dropdown">
            <button
              class="inline-flex items-center px-4 py-2 transition-colors duration-300 rounded lg:px-3 md:mx-2"
            >
              <span class="mr-1 font-bold"> {{ $t("nav3.projects") }} </span>
              <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </button>
            <ul
              class="absolute z-50 hidden transition-all duration-1000 ease-in-out bg-gray-100 border border-gray-200 rounded shadow-2xl w-52 dropdown-menu"
            >
              <router-link
                v-for="project in dropdownProject"
                :key="project.name"
                @click.native="scrollToTop"
                :to="project.route"
                class="z-50 block px-4 py-2 whitespace-no-wrap border-l hover:bg-gray-200"
              >
                {{ $t(`project.${project.name}`) }}
              </router-link>
            </ul>
          </div>
          <router-link
            v-for="nav in navs2"
            :key="nav.name"
            @click.native="scrollToTop"
            :to="nav.route"
            class="py-2 font-bold transition-colors duration-300 border-b-2 border-transparent hover:border-esi-btn lg:mx-4 md:mx-3"
          >
            {{ $t(`nav2.${nav.name}`) }}
          </router-link>
          <a
            rel="noreferrer noopener"
            href="https://donorbox.org/strike-out-stroke"
            target="_blank"
            class="w-64 p-2 font-bold transition-colors duration-300 border rounded-full text-cente md:w-auto lg:px-6 md:mx-2 border-esi-btn text-esi-primary hover:bg-esi-btn hover:text-white"
          >
            Donate
          </a>
          <LocaleSwitcher></LocaleSwitcher>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import LocaleSwitcher from "../LocaleSwitcher.vue";
export default {
  components: { LocaleSwitcher },
  name: "Header",
  data() {
    return {
      navs1: [
        {
          name: "home",
          route: "/",
        },
      ],
      navs2: [
        {
          name: "about-us",
          route: "/about-us",
        },
        {
          name: "our-vision",
          route: "/our-vision",
        },
        {
          name: "contact-us",
          route: "/contact-us",
        },
      ],
      dropdown: [
        {
          name: "definition",
          route: "/definition",
        },
        {
          name: "types",
          route: "/types-of-stroke",
        },
        {
          name: "symptoms",
          route: "/symptoms",
        },
        {
          name: "risk-factors",
          route: "/risk-factors",
        },
      ],
      dropdownProject: [
        {
          name: "stroke-treatment",
          route: "/treatment",
        },
        {
          name: "stroke-research",
          route: "/research",
        },
        {
          name: "followship-training",
          route: "/training",
        },
        {
          name: "community-education",
          route: "/education",
        },
      ],
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
#appointment {
  color: #fff !important;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
