<template>
  <div>
    <div v-if="!loading" class="max-w-6xl px-5 mx-auto my-20 text-gray-600">
      <section>
        <div
          v-for="(m, index) in intro"
          :key="m.id"
          class="flex flex-col items-center mx-auto my-48 lg:flex-row"
        >
          <div
            class="flex flex-col order-1 lg:w-1/2 lg:flex-grow lg:pr-10"
            :class="index % 2 === 0 ? 'md:order-1 p-8' : 'md:order-2 p-8'"
          >
            <h1
              class="mb-3 text-3xl font-medium title-font sm:text-4xl text-esi-primary"
            >
              {{ m.attributes.title }}
            </h1>
            <div>
              <p class="mb-2">
                <VueShowdown :markdown="m.attributes.content" />
              </p>
              <a
                :href="m.attributes.readMore"
                class="text-sm transition-colors duration-300 cursor-pointer text-esi-secondary hover:underline"
              >
                {{ $t("learn-more") }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="inline-block w-4 h-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div
            class="w-full lg:max-w-lg lg:w-1/2 md:w-full"
            :class="index % 2 === 0 ? 'order-2' : 'order-1'"
          >
            <img
              class="object-cover object-center rounded-sm"
              :alt="m.attributes.title"
              :src="getBaseUrl() + m.attributes.image.data.attributes.url"
            />
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Intro",
  data() {
    return {
      intro: [],
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiIntro",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.intro = response.data.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style scoped></style>
