var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"hero",style:({
      backgroundImage:
        'url(' +
        _vm.getBaseUrl() +
        _vm.definition.attributes.image.data.attributes.url +
        ')',
      'object fit': 'cover',
      'background-repeat': 'no-repeat',
      'z-index': '2',
      'background-size': 'cover',
      'background-position': 'center',
      position: 'relative',
    })},[_c('div',{staticClass:"left-0 flex flex-col items-center justify-center h-full text-gray-200 bg-black bg-opacity-40"},[_c('div',{staticClass:"p-4 mx-8 text-center"},[_c('p',{staticClass:"text-2xl font-bold text-gray-200 md:text-5xl"},[_vm._v(" "+_vm._s(_vm.definition.attributes.title)+" ")])])])]),_c('div',{staticClass:"container max-w-4xl px-4 py-16 mx-auto"},[_c('h1',{staticClass:"mb-3 text-4xl font-semibold text-gray-800"},[_vm._v(" "+_vm._s(_vm.definition.attributes.subtitle)+" ")]),_c('p',{staticClass:"leading-relaxed text-gray-700"},[_c('VueShowdown',{attrs:{"markdown":("" + (_vm.definition.attributes.mainContent))}})],1)]),_c('div',{staticClass:"container max-w-4xl px-4 py-8 mx-auto mb-10 text-gray-600 bg-gray-50"},[_c('p',{staticClass:"mb-4"},[_c('VueShowdown',{attrs:{"markdown":("" + (_vm.definition.attributes.subContent))}})],1),_c('router-link',{staticClass:"px-3 py-2 mt-6 text-sm text-white rounded hover:opacity-80 bg-esi-btn",attrs:{"to":"types-of-stroke"}},[_vm._v(" "+_vm._s(_vm.$t("find-out-more"))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }