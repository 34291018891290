<template>
  <div>
    <VisionHero></VisionHero>
    <div v-if="!loading" class="max-w-6xl px-5 mx-auto text-gray-600">
      <section>
        <div
          v-for="(v, index) in visions"
          :key="v.id"
          class="flex flex-col items-center mx-auto my-48 lg:flex-row"
        >
          <div
            class="flex flex-col order-1 mb-10 lg:w-1/2 lg:flex-grow lg:pr-10"
            :class="index % 2 === 0 ? 'md:order-1 p-8' : 'md:order-2 p-8'"
          >
            <h1 class="mb-4 text-2xl font-bold sm:text-4xl text-esi-primary">
              {{ v.attributes.title }}
            </h1>
            <div>
              <p class="mb-8 leading-relaxed">
                <VueShowdown :markdown="`${v.attributes.content}`" />
              </p>
            </div>
          </div>
          <div
            class="w-full lg:max-w-lg lg:w-1/2 md:w-full"
            :class="index % 2 === 0 ? 'order-2' : 'order-1'"
          >
            <img
              class="object-cover object-center rounded-sm"
              :alt="v.attributes.title"
              :src="getBaseUrl() + v.attributes.image.data.attributes.url"
            />
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import VisionHero from "../components/VisionHero.vue";
import { mapGetters } from "vuex";

export default {
  name: "Vision",
  components: { VisionHero },
  data() {
    return {
      visions: [],
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiVisions",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.visions = response.data.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style scoped></style>
