<template>
  <section class="relative max-w-6xl px-5 py-20 mx-auto text-gray-600">
    <div class="flex flex-wrap w-full mb-10">
      <div class="flex flex-col w-full lg:w-1/2 lg:mb-0">
        <h1 class="mb-2 text-2xl font-semibold text-gray-900 sm:text-3xl">
          {{ $t("our-hospital-is-24-hours-open") }}
        </h1>
        <div class="flex space-x-4">
          <svg class="w-6 h-6" viewBox="0 0 20 20" fill="#D76738">
            <path
              d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
            />
          </svg>
          <span class="font-semibold">+251940155606</span>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap mx-auto">
      <div
        class="relative flex items-end justify-start p-10 overflow-hidden bg-gray-300 rounded-sm lg:w-1/2 md:w-full"
      >
        <iframe
          width="100%"
          height="100%"
          class="absolute inset-0"
          frameborder="0"
          title="map"
          marginheight="0"
          marginwidth="0"
          scrolling="no"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Samaritan%20surgical%20center+(Samaritan%20surgical%20center)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
        </iframe>
        <div
          class="relative flex flex-wrap py-6 bg-white rounded shadow-md opacity-0 md:opacity-0"
        >
          <div class="px-6 lg:w-1/2">
            <h2 class="text-xs font-semibold tracking-widest text-gray-900">
              PHYSICAL ADDRESS
            </h2>
            <p class="mt-1">
              Inside the Sunshine Real-Estate compound (Meri Luke) <br />
              Yeka Sub City, Kebele 20/21 <br />
              P.O. Box 58259 <br />
              Addis Ababa, Ethiopia
            </p>
          </div>
          <div class="px-6 mt-4 lg:w-1/2 lg:mt-0">
            <h2
              class="text-xs font-semibold tracking-widest text-gray-900 title-font"
            >
              EMAIL
            </h2>
            <a class="leading-relaxed text-indigo-500"
              >ethiopiastroke@gmail.com</a
            >
            <h2
              class="mt-4 text-xs font-semibold tracking-widest text-gray-900 title-font"
            >
              PHONE
            </h2>
            <p class="leading-relaxed">+251 116 680808</p>
            <p class="leading-relaxed">+251 116 681516</p>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col w-full mt-8 bg-white lg:pl-10 lg:w-1/2 md:py-8 lg:py-0 md:mt-0 sm:w-4/5 sm:mx-auto"
      >
        <h2 class="mb-1 text-2xl font-bold text-gray-900">
          {{ $t("contact-us") }}
        </h2>
        <p class="mb-3 leading-relaxed">{{ $t("leave-us-a-message-here") }}</p>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
            <div class="relative mb-4">
              <label for="name" class="text-sm leading-7 text-gray-600">
                {{ $t("name") }}
              </label>
              <ValidationProvider
                name="Name"
                rules="required|alpha"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  id="name"
                  v-model="name"
                  name="name"
                  :placeholder="$t('name')"
                  class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-blue-500 focus:bg-white"
                />
                <span class="text-sm text-red-600">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="relative mb-4">
              <label for="email" class="text-sm leading-7 text-gray-600">{{
                $t("email")
              }}</label>
              <ValidationProvider
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <input
                  type="email"
                  id="email"
                  v-model="email"
                  name="email"
                  :placeholder="$t('email')"
                  class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-blue-500 focus:bg-white"
                />
                <span class="text-sm text-red-600">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="relative mb-4">
              <label for="message" class="text-sm leading-7 text-gray-600">
                {{ $t("message") }}
              </label>
              <ValidationProvider
                name="Message"
                rules="required"
                v-slot="{ errors }"
              >
                <textarea
                  id="message"
                  v-model="message"
                  name="message"
                  :placeholder="$t('message')"
                  class="w-full h-32 px-3 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-white border border-gray-300 rounded-sm outline-none resize-none focus:border-blue-500 focus:bg-white"
                ></textarea>
                <span class="text-sm text-red-600">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <button
              loading="true"
              type="submit"
              value="send"
              class="w-full px-6 py-3 text-white transition-colors duration-200 ease-in-out border-0 rounded-sm bg-esi-primary focus:outline-none hover:bg-opacity-80"
            >
              {{ $t("send-message") }}
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import emailjs from "emailjs-com";
import { baseUrl } from "../Api";
import { TYPE } from "vue-toastification";

export default {
  name: "Contact",
  data: () => ({
    name: "",
    email: "",
    message: "",
    error: false,
    loading: false,
  }),
  methods: {
    showToast(type, message) {
      type;
      message;
      this.$toast.type(`${message}`);
    },
    async onSubmit(e) {
      const success = await this.$refs.form.validate();
      if (success) {
        try {
          axios
            .post(`${baseUrl}/api/contact-uses`, {
              data: {
                name: this.name,
                email: this.email,
                message: this.message,
              },
            })
            .then(() => {
              emailjs.sendForm(
                "service_zlnw8io",
                "template_b0f7soj",
                e.target,
                "user_pHlXRSBk1ewiAuGzuc7jE"
              );
              this.$toast("Thanks for contacting us", {
                type: TYPE.SUCCESS,
              });
              // this.$nextTick(() => {
              //   this.$refs.form.reset();
              //   this.name = "";
              //   this.email = "";
              //   this.message = "";
              // });
            });
        } catch (error) {
          this.error = error;
        }
      } else {
        this.$toast("Please fill out the form", {
          type: TYPE.ERROR,
        });
      }
    },
  },
};
</script>

<style></style>
