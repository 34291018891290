var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"text-gray-700"},[_c('div',{style:({
      backgroundImage:
        'url(' +
        _vm.getBaseUrl() +
        _vm.treatment.attributes.image.data.attributes.url +
        ')',
      height: 256 + 'px',
      'object fit': 'cover',
      'padding-bottom': 33.3333 + '%',
      'background-repeat': 'no-repeat',
      'z-index': '2',
      'background-size': 'cover',
      position: 'relative',
    })}),_c('div',{staticClass:"container max-w-4xl px-4 py-16 mx-auto"},[_c('h1',{staticClass:"mb-3 text-4xl font-semibold text-gray-800"},[_vm._v(" "+_vm._s(_vm.treatment.attributes.title)+" ")]),_c('p',{staticClass:"leading-relaxed"},[_c('VueShowdown',{attrs:{"markdown":("" + (_vm.treatment.attributes.content))}})],1),_c('div',{staticClass:"mt-4"},[(_vm.treatment.attributes.readMore)?_c('a',{staticClass:"text-sm transition-colors duration-300 cursor-pointer text-esi-secondary hover:underline",attrs:{"target":"_blank","href":_vm.treatment.attributes.readMore}},[_vm._v(" Read more "),_c('svg',{staticClass:"inline-block w-5 h-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z","clip-rule":"evenodd"}})])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }